<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-row class="auth-inner py-2" align-h="center" v-if="isCreatingUser">
      <b-card>
        <b-col cols="12">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t('contractForm.head1') }}
          </b-card-title>

          <b-card-text class="mb-2">
            {{ $t('contractForm.body1') }}
          </b-card-text>

          <!-- user form -->
          <validation-observer ref="registerValidation" #default="{ invalid }">
            <b-form class="auth-form mt-2" @submit.prevent>
              <b-row>
                <b-col cols="12" md="6" lg="6" sm="6">
                  <!-- name -->
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('contractForm.name')"
                      label-for="name"
                    >
                      <b-form-input
                        v-model="name"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length }"
                        :state="errors.length ? false : null"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col>
                  <!-- last name -->
                  <validation-provider
                    #default="{ errors }"
                    name="last name"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('contractForm.last')"
                      label-for="lastName"
                    >
                      <b-form-input
                        v-model="lastName"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length }"
                        :state="errors.length ? false : null"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="email|required"
                  >
                    <b-form-group
                      :label="$t('contractForm.email')"
                      label-for="email"
                    >
                      <b-form-input
                        v-model="userEmail"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length }"
                        :state="errors.length ? false : null"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <!-- password -->
              <b-form-group
                :label="$t('contractForm.password')"
                label-for="register-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="{ 'is-invalid': errors.length }"
                  >
                    <b-form-input
                      v-model="password"
                      id="register-password"
                      class="form-control-merge"
                      name="register-password"
                      :type="passwordFieldType"
                      :state="errors.length ? false : null"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                :label="$t('contractForm.cPassword')"
                label-for="register-confirm-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="confirm password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="{
                      'is-invalid':
                        errors.length || (confirmPassword && !valid),
                    }"
                  >
                    <b-form-input
                      v-model="confirmPassword"
                      id="register-confirm-password"
                      class="form-control-merge"
                      name="register-confirm-password"
                      :type="showConfirmPw ? 'text' : 'password'"
                      :state="
                        errors.length || (confirmPassword && !valid)
                          ? false
                          : null
                      "
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="!showConfirmPw ? 'EyeIcon' : 'EyeOffIcon'"
                        @click="showConfirmPw = !showConfirmPw"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{
                    confirmPassword && !valid
                      ? $t('contractForm.pw-dont-match')
                      : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Changing roles -->
              <validation-provider
                #default="{ errors }"
                name="Roles"
                rules="required"
              >
                <b-form-group
                  :label="`${$t('requestAccess.roles')}*`"
                  label-for="roles"
                >
                  <v-select
                    v-model="chosenRole"
                    label="label"
                    :placeholder="$t('requestAccess.select')"
                    :options="roles"
                    :clearable="false"
                    :reduce="(option) => option.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <label class="pb-1"> {{$t('requestAccess.opticPlans.wouldLikeToRegister')}} <strong>{{$t('requestAccess.opticPlans.optic')}}</strong> <a href="/#Planos">{{$t('requestAccess.opticPlans.goToPlans')}}</a></label>
              
              <!-- submit buttons -->
              <b-button
                block
                type="submit"
                :disabled="!valid || invalid"
                :variant="valid && !invalid ? 'primary' : 'secondary'"
                @click="register()"
              >
                <b-spinner
                  v-if="loading"
                  small
                  :label="$t('requestAccess.loading')"
                />

                <span v-else>{{ $t('contractForm.signup') }}</span>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-card>
    </b-row>

    <b-row v-else align-h="center" style="margin-top: 2em">
      <b-col cols="12" lg="10">
        <b-card :title="$t('requestAccess.createEntity')">
          <lab-form
            v-if="isLab"
            :isFromRequestAccessForm="true"
            @update-data="updateEntity"
            @update-valid="updateValid"
          />
          <software-partner-form
            v-else-if="isSP"
            :isFromRequestAccessForm="true"
            @update-data="updateEntity"
            @update-valid="updateValid"
          />

          <b-row align-h="end" class="mr-1">
            <b-button :disabled="!formValid" @click="saveEntity()">
              {{ $t('requestAccess.save') }}</b-button
            >
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BCard,
  BCardTitle,
  BCardText,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormDatepicker,
  BLink,
  BInputGroupAppend,
  BRow,
  BSpinner,
} from 'bootstrap-vue'

import { computed, ref, onUnmounted } from '@vue/composition-api'

import { required, integer, positive, email, numeric } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import store from '../../store'

import useSignUp from './useSignUp'
import signUpStoreModule from './signUpStoreModule'

import softwarePartnerStoreModule from '../sections/software-partners/softwarePartnerStoreModule'
import labStoreModule from '../sections/labs/labStoreModule'
import opticStoreModule from '../sections/optics/opticStoreModule'
import sellerStoreModule from '../sections/sellers/sellerStoreModule'
import vSelect from 'vue-select'
import RoleEnum from '@/utils/roles'
import SoftwarePartnerForm from '@/views/sections/software-partners/SoftwarePartnerForm.vue'
import LabForm from '@/views/sections/labs/LabForm.vue'
import AuthController from '@/controllers/auth.controller'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'RequestAccess',

  components: {
    BCard,
    BCardTitle,
    BCardText,
    BCol,
    BRow,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BFormDatepicker,
    BInputGroup,
    BLink,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
    vSelect,
    SoftwarePartnerForm,
    LabForm,
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {
      required,
      integer,
      positive,
      email,
      numeric,

      formValid: false,
      showConfirmPw: false,
      chosenRole: null,
      entityData: null,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    isLab() {
      return (
        (this.chosenRole || (this.user && this.user.role)) ===
        RoleEnum.LABORATORY_ADMIN
      )
    },
    isSP() {
      return (
        (this.chosenRole || (this.user && this.user.role)) ===
        RoleEnum.SOFTWARE_ADMIN
      )
    },
    roles() {
      const allRoles = RoleEnum.listRoles()
      return allRoles.filter(
        (el) =>
          el.value === RoleEnum.LABORATORY_ADMIN ||
          el.value === RoleEnum.SOFTWARE_ADMIN,
      )
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async register() {
      // Check if user already exists on auth
      const userExists = await new AuthController().checkIfUserExists(
        this.userEmail,
      )
      if (!userExists) {
        await this.requestAccess(this.chosenRole)
      } else {
        this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('firebase.error.emailInUse'),
              icon: 'XIcon',
              variant: 'danger',
            },
          })
      }
    },
    async saveEntity() {
      this.entityData.userEmail = this.userEmail
      await this.createEntityForAccessRequest(this.chosenRole, this.entityData)
    },
    updateValid(value) {
      this.formValid = value
    },
    updateEntity(value) {
      this.entityData = Object.assign({}, value)
    },
  },

  mounted() {
    if (!this.user) {
      this.isCreatingUser = true
      return
    }

    if (this.user.status) {
      this.$router.push('/')
      return
    }

    this.isCreatingUser = false
    this.name = this.user.name
    this.lastName = this.user.lastName
    this.userEmail = this.user.email
    this.phoneNumber = this.user.phoneNumber
    this.chosenRole = this.user.role
  },

  setup() {
    const SOFTWARE_PARTNER_APP_STORE_MODULE_NAME = 'software-partner'
    const LAB_APP_STORE_MODULE_NAME = 'laboratory'
    const OPTIC_APP_STORE_MODULE_NAME = 'optic'
    const SELLER_APP_STORE_MODULE_NAME = 'seller'
    const SIGN_UP_APP_STORE_MODULE_NAME = 'sign-up'

    if (!store.hasModule(SIGN_UP_APP_STORE_MODULE_NAME)) {
      store.registerModule(SIGN_UP_APP_STORE_MODULE_NAME, signUpStoreModule)
    }

    if (!store.hasModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        SOFTWARE_PARTNER_APP_STORE_MODULE_NAME,
        softwarePartnerStoreModule,
      )
    }

    if (!store.hasModule(LAB_APP_STORE_MODULE_NAME)) {
      store.registerModule(LAB_APP_STORE_MODULE_NAME, labStoreModule)
    }

    if (!store.hasModule(OPTIC_APP_STORE_MODULE_NAME)) {
      store.registerModule(OPTIC_APP_STORE_MODULE_NAME, opticStoreModule)
    }

    if (!store.hasModule(SELLER_APP_STORE_MODULE_NAME)) {
      store.registerModule(SELLER_APP_STORE_MODULE_NAME, sellerStoreModule)
    }

    onUnmounted(() => {
      store.hasModule(SIGN_UP_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SIGN_UP_APP_STORE_MODULE_NAME)

      store.hasModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME)

      store.hasModule(LAB_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(LAB_APP_STORE_MODULE_NAME)

      store.hasModule(OPTIC_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(OPTIC_APP_STORE_MODULE_NAME)

      store.hasModule(SELLER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SELLER_APP_STORE_MODULE_NAME)
    })

    const {
      name,
      lastName,
      email,
      phoneNumber,
      city,
      password,
      address,
      loading,
      registerValidation,
      requestAccess,
      isSubscribingToPlan,
      isCreatingUser,
      createEntityForAccessRequest,
    } = useSignUp()

    const confirmPassword = ref('')

    const valid = computed({
      get: () => password.value && password.value === confirmPassword.value,
    })

    return {
      name,
      lastName,
      userEmail: email,
      phoneNumber,
      city,
      password,
      confirmPassword,
      address,

      loading,
      isCreatingUser,
      valid,
      registerValidation,
      isSubscribingToPlan,

      requestAccess,
      createEntityForAccessRequest,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
